import React, { Component } from "react"

import Layout from "../components/layout"
import Header from "../components/header"

class OpenPage extends Component{

  componentDidMount() {
    const stripe = window.Stripe('pk_live_51HYT2LGqF8BKdamMTqXPsEU1NCtJEjSklKi2ddSwo61ACWZKoEq2trjpDqYBDQTRpTmlR5E68wRnthqyqZ9c6IXF00MCGrheo4'); // prod
    // const stripe = window.Stripe('pk_test_51HYT2LGqF8BKdamM8RNTjBbDkXsH8h1e1rNOcw9eQOPJx00Fwf09si7yYklsLPbvwHWVnCTSlDYiH1GRYJzeefRQ00H9aANKhm'); // test
    // Create a new Checkout Session using the server-side endpoint you
    // created in step 3.
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const userEmail = urlParams.get('userEmail')

    // fetch(`http://localhost:5000/linksaber-97603/us-central1/api/v1/billing/create-checkout-session?userEmail=${userEmail}`, {
    fetch(`https://us-central1-linksaber-97603.cloudfunctions.net/api/v1/billing/create-checkout-session?userEmail=${userEmail}`, {
      method: 'POST',
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(session) {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function(result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function(error) {
        console.error('Error:', error);
      });
  }

  render() {
    return (
      <Layout>
        <script src="https://js.stripe.com/v3/"/>
        <Header/>

        <p className="section-body text-gray-400">
          Redirecting to Stripe Checkout...
        </p>

      </Layout>
    )
  }
}

export default OpenPage